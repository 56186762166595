<template>
    <div>
        <fieldset style="border: 1px solid #e0e0e0; border-radius: 5px;"
                  class="p-3 pt-2 pb-2">
            <div class="custom-control custom-radio d-inline-block mr-3 mb-3">
                <input class="custom-control-input"
                       type="radio"
                       id="gpuHub"
                       value="gpuHub"
                       checked
                       v-model="balanceDeductionModel.pickedSource" />
                <label for="gpuHub" class="custom-control-label">Gpu Hub Balance deduction</label>
            </div>
            <!--<div class="custom-control custom-radio d-inline-block">
                                                <input class="custom-control-input" type="radio" id="renderFarm" value="renderFarm" v-model="balanceDeductionModel.pickedSource">
                                                <label for="renderFarm" class="custom-control-label">Render farm</label>
            </div>-->

            <div class="mt-2">
                <div>
                    <div class="text-right mb-3 mr-2">
                        <span class="mr-3">Select a prefix amount or input</span>
                        <el-button-group>
                            <el-button type="primary" 
                                       @click="balanceDeductionModel.pointConversion = 5" 
                                       class="bg-gradient-blue">
                                <strong>5 {{$pointNameShort}}</strong>
                            </el-button>
                            <el-button type="primary" 
                                       @click="balanceDeductionModel.pointConversion = 10" 
                                       class="bg-gradient-blue">
                                <strong>10 {{$pointNameShort}}</strong>
                            </el-button>
                            <el-button type="primary" 
                                       @click="balanceDeductionModel.pointConversion = 50" 
                                       class="bg-gradient-blue">
                                <strong>50 {{$pointNameShort}}</strong>
                            </el-button>
                            <el-button type="primary" 
                                       @click="balanceDeductionModel.pointConversion = 100" 
                                       class="bg-gradient-blue">
                                <strong>100 {{$pointNameShort}}</strong>
                            </el-button>
                        </el-button-group>
                    </div>
                    <div class="flex-fill mr-2">
                        <div class="d-flex mt-2 align-items-center">
                            <div style="width: 8rem;">
                                <span>Main Balance ({{$pointNameShort}})</span>
                            </div>
                            <div class="flex-fill">
                                <el-input-number v-model="balanceDeductionModel.pointConversion"
                                                 :precision="4"
                                                 :step="1"
                                                 :max="100000"
                                                 size="small"
                                                 class="w-100"></el-input-number>
                            </div>
                        </div>
                        <div class="d-flex mt-2 align-items-center">
                            <div style="width: 8rem;">
                                <span>Extra Balance ({{$pointNameShort}})</span>
                            </div>
                            <div class="flex-fill">
                                <el-input-number v-model="balanceDeductionModel.extraPointConversion"
                                                 :precision="4"
                                                 :step="1"
                                                 :max="100000"
                                                 size="small"
                                                 class="w-100"></el-input-number>
                            </div>
                        </div>
                        <div class="d-flex mt-2 align-items-center">
                            <div style="width: 8rem;">
                                <span>Input note</span>
                            </div>
                            <div class="flex-fill">
                                <el-input placeholder="Please input note.."
                                      v-model="balanceDeductionModel.reason"
                                      clearable></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mb-2 mr-2 bg-warning p-2 text-navy"
                         v-if="(balanceDeductionModel.pointConversion + balanceDeductionModel.extraPointConversion) > 0 && balanceDeductionModel.reason">
                        Make a balance deduction, worth <strong class="text-navy">{{balanceDeductionModel.pointConversion}} {{$pointNameShort}}</strong> from main balance
                        and <strong class="text-navy">{{balanceDeductionModel.extraPointConversion}} {{$pointNameShort}}</strong> from extra balance 
                        with the note "<strong class="text-navy"><i>{{balanceDeductionModel.reason}}</i></strong>". Click Execute to perform action
                    </div>
                    <div class="mt-3 d-flex justify-content-between">
                        <button type="button"
                                class="btn btn-lg btn-default mr-3 text-nowrap"
                                @click="closeModal()">
                            <i class="fas fa-times"></i> Close
                        </button>
                        <button type="button"
                                class="btn btn-lg btn-primary mr-1 text-nowrap"
                                :disabled="(balanceDeductionModel.pointConversion + balanceDeductionModel.extraPointConversion) <= 0 || !balanceDeductionModel.reason || balanceDeductionModel.reason === ''"
                                @click="balanceDeduction()">
                            <i class="far fa-play-circle"></i> Execute
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import usersApi from "@/api/common/users";
    import commonAct from "@/commonActionHandle.js";
    import baseComponent from "@/scripts/baseComponent";
    import { mapState } from "vuex";
    import moment from 'moment';
    import { providerSelection } from "@/constant/config";
    export default {
        extends: baseComponent,
        props: {
            detailUserItem: {
                type: Object,
                default() {
                    return null;
                },
            },
        },
        watch: {
            detailUserItem(newVal) {
                if (this.detailUserItem !== null) {
                    this.addRefundSelection();
                }
            },
        },
        created() {
            this.addRefundSelection();
        },
        data() {
            return {
                balanceDeductionModel: {
                    pickedSource: "gpuHub",
                    pointConversion: 0.0,
                    extraPointConversion : 0.0,
                    userId: this.detailUserItem.id,
                },
                providerSelection: providerSelection,
            }
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
            }),  
        },
        methods: {
            addRefundSelection() {
                //let findIndex = this.providerSelection.findIndex(
                //    (x) => x.id === "REFUND"
                //);

                //if (findIndex < 0) {
                //    this.providerSelection = this.providerSelection.concat(
                //        this.$emailAllowedChangeSource.includes(this.userInfo.userEmail)
                //            ? [{ id: "REFUND", text: "REFUND" }]
                //            : []
                //    );
                //}
            },
            balanceDeduction() {
                commonAct.showConfirm(
                    `Balance Deduction for user <strong>${this.detailUserItem.fullName}</strong>, point <strong>${this.balanceDeductionModel.pointConversion}${this.$pointNameShort}</strong> ?`,
                    () => {
                        this.showLoading();
                        this.balanceDeductionModel.userId = this.detailUserItem.id;
                        this.balanceDeductionModel.deviceInformation = JSON.stringify(
                            this.getInformationClient()
                        );
                        usersApi
                            .balanceDeduction(this.balanceDeductionModel)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.hideLoading();
                                    this.showSuccessToast(
                                        "Balance Deduction has been success"
                                    );
                                    if (this.balanceDeductionModel.pickedSource === "gpuHub") {
                                        this.detailUserItem.gpuHubAccountBalance -= this.balanceDeductionModel.pointConversion;
                                        this.detailUserItem.gpuHubExtraBalance -= this.balanceDeductionModel.extraPointConversion;
                                    }
                                    this.$emit("closeModal");
                                } else {
                                    this.hideLoading();
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                this.hideLoading();
                                commonAct.showError(error);
                            });
                    }
                );
            },
            updatePoint(val) {
                this.$set(this.balanceDeductionModel, "pointConversion", val);
            },
            closeModal() {
                this.$emit("closeModal");
            }
        }
    }
</script>